.ant-table-thead .ant-table-cell {
    background-color: red;
  }


#edit{
    padding: 0 !important;
    width: 90%;
    margin: 0 auto;
    margin-top: -10px;
    height:  89vh;    
    position: absolute;
}

#pending{
  padding: 0 !important;
  width: 40%;
  margin: 0 auto;
  margin-top: 25px;
  height:  39vh;    
  position: absolute;
}
.frame
{
    width: 1180px;
    height: 550px;
    border: 0;

    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

#new8{
    border: 1px solid black ;
    width: 40%;
    margin: 0 auto;
    margin-top: 0px;
    height: 85vh;
    transition: fadein 2s fadein;
    display: block;
    z-index: 10002;
    padding: 0 !important;
}

#new9{
    border: 1px solid black ;
    width: 40%;
    margin: 0 auto;
    margin-top: 23px;
    height: 40vh;
    transition: fadein 2s fadein;
    display: block;
    z-index: 10002;
    padding: 0 !important;
}

#new10{
    border: 1px solid black ;
    width: 30%;
    margin: 0 auto;
    margin-top: 23px;
    height: 30vh;
    transition: fadein 2s fadein;
    display: block;
    z-index: 10002;
    padding: 0 !important;
}

#new11{
    border: 1px solid black ;
    width: 75%;
    margin: 0 auto;
    margin-top: 23px;
    height: 75vh;
    transition: fadein 2s fadein;
    display: block;
    z-index: 10002;
    padding: 0 !important;
}
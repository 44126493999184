span{
  font-size: 14px;
}
    
  #email{
    display: none;
  }
  .smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
    padding: 0 !important;
  }
  
  .smartphone:after {
  
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
    padding: 0 !important;
  }
  .smartphone .content {
    width: 321px;
    height: 520px;
    background: white;
    padding: 0 !important;
  }

  #code{
    width: 90%;
    margin: 0 auto;
    margin-top: 1%;
    height: 81vh;
    font-size: 1.2em;
    padding: 0 !important;
  }

  #banner{
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
    height: 81vh;
    font-size: 1.2em;
    padding: 0 !important;
  }

  
#emoji{
  width: 26%;
  margin: 0 auto;
  margin-top: 85px;
  height: 50vh;
  padding: 0 !important;
}

#newreo{
  width: 45%;
  margin: 0 auto;
  margin-top: 85px;
  height: 190px;
  padding: 0 !important;
}

#crop{
  width: 90%;
  margin: 0 auto;
  /* margin-top: 85px; */
  height: 90vh;
  padding: 0 !important;
}

#show_template{
  width: 65%;
  margin: 0 auto;
  /* margin-top: 85px; */
  height: 75vh;
  padding: 0 !important;
}

#newheader{
  width: 79%;
  margin: 0 auto;
  margin-top: -37px;
  height: 90vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#palette{
  width: 14%;
  margin: 0 auto;
  margin-top: 85px;
  height: 430px;
  padding: 0 !important;
}

#links{
  width: 30%;
  margin: 0 auto;
  margin-top: 85px;
  height: 42vh;
  padding: 0 !important;
}

#font{
  width: 50%;
  margin: 0 auto;
  margin-top: 85px;
  height: 72vh;
  padding: 0 !important;
}

#variables{
  width: 18%;
  margin: 0 auto;
  margin-top: 85px;
  height: 54vh;
  padding: 0 !important;
}

#emoji{
  width: 26%;
  margin: 0 auto;
  margin-top: 85px;
  height: vh;
  padding: 0 !important;
}

#remove_template{
  width: 40%;
  margin: 0 auto;
  margin-top: 85px;
  height: 25vh;
  padding: 0 !important;
}

#assign_template{
  width: 40%;
  margin: 0 auto;
  margin-top: 85px;
  height: 45vh;
  padding: 0 !important;
}

#newdwh{
  width: 69%;
  margin: 0 auto;
  margin-top: 0px;
  height: 77vh;
  overflow-x: hidden;
  padding: 0 !important;
}


#edit_message{
  width: 60%;
  margin: 0 auto;
  margin-top: -15px;
  margin-left: 19%;
  height: 82vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#drop_message{
  width: 40%;
  margin: 0 auto;
  margin-top: 4%;
  margin-left: 28%;
  height: 30vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#scheduled_message{
  width: 60%;
  margin: 0 auto;
  height: 70vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#group{
  width: 69%;
  margin: 0 auto;
  margin-top: 4%;
  margin-left: 19%;
  height: 77vh;
  overflow-x: hidden;
  padding: 0 !important;
}
#newseed{
  width: 40%;
  margin: 0 auto;
  margin-top: 4%;
  /* margin-left: 1%; */
  height: 45vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#addseed{
  width: 40%;
  margin: 0 auto;
  margin-top: 4%;
  /* margin-left: 1%; */
  height: 35vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#newsub{
  width: 40%;
  margin: 0 auto;
  margin-top: 4%;
  /* margin-left: 1%; */
  height: 60vh;
  overflow-x: hidden;
  padding: 0 !important;
}
#addsub{
  width: 40%;
  margin: 0 auto;
  margin-top: 4%;
  /* margin-left: 1%; */
  height: 50vh;
  overflow-x: hidden;
  padding: 0 !important;
}

.box-container {
  width: 100%;
}
.box-card-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    touch-action: none;
}

.box-footer {
    width: 100%;
    min-height: 50px;
    height: auto;
    max-height: 100px;
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 40px;
}


.box-card {
    width: 220px;
    height: 275px;
    padding: 8px;
    cursor: grab;
    user-select: none;
    background-color: #fefefe;
}

.is-dragged {
    /*pointer-events: none;*/
    touch-action: none;
    cursor: grabbing;
}

.box-card-content {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}
.box-card-disabled .box-card-content{
  background-color: #c3c3c3;
}
.box-card-img {
    width: 100%;
    height: 150px;
    position: relative;
}

.box-card-img .form-group-action {
    position: absolute;
    top: 10px;
    right: 10px; 
}

.box-card-img .form-group-action > .form-action {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: none;
    border: none;
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: #fefefe;
    display: inline-block;
    

    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin-left: 4px;

    cursor: pointer;

    transition: all 0.3s;
    
}
.box-card-disabled .box-card-img .form-group-action > .form-action{
  background-color: #fadbde;
  border: 1px solid #fadbde;
  color: #000;
}
.box-card-img .form-group-action > .form-action:hover {
    color: #dc3545;
    background-color: #fefefe;
    transition: all 0.3s;
}

.box-card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box-card-disabled .box-card-img img {
  opacity: 0.3;
}

.box-card-body {
    padding: 10px 10px 0;
}
.box-card-body h5 {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    transition: all 0.3s;
}

.box-card-body p {
    font-size: 12px;
    margin-bottom: 0 !important;
}

.box-notif-container {
    position: fixed;
    bottom: 0;
    right: 0;
}

.box-notif {
    border-radius: 5px;
    width: 300px;
    height: auto;
    text-align: left;
    padding: 10px;
    /*background-color: rgba(7, 149, 66, 0.12156862745098039);*/
    background-color: #fefefe;
    cursor:pointer;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
    color: #198754;
    margin: 10px 20px;
    font-size: 16px;

    animation: slideIn 5s ease forwards;
}

.box-notif-danger {
    color: #fc3545;
}

.box-notif p {
    margin: 0;
    display: inline;
    vertical-align: middle;
    line-height: 2;
}
.box-notif span {
    float: right;
    font-size: 24px;
    animation: colorAnimation 1s linear infinite;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid;
    border-radius: 50%;
    text-align: center;
}

  .box-list-container > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 800px;
    height: 100vh;
    overflow-y: auto;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .box-list-container > ul > li {
    margin: 0;
    position: relative;
    padding: 10px 10px 10px 85px;
    border-top: 1px solid #ededed;
    background-color: #fefefe;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    .box-list-container > ul > li {
      /* For less than 767px */
      padding-left: 30px;
    }
  }
  .box-list-container > ul > li:hover {
    background-color: #f8f9fa;
  }
  .box-list-container > ul > li figure {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 5px;
    top: 5px;
  }
  @media (max-width: 767px) {
    .box-list-container > ul > li figure {
      position: relative;
      top: 0;
      left: 0;
    }
  }
  .box-list-container > ul > li figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .box-list-container > ul h5 {
    font-size: 16px;
    font-weight: 700;
  }
  .box-list-container > ul span {
    font-style: italic;
    color: #adb5bd;
  }
  @media (max-width: 767px) {
    .box-list-container > ul span {
      float: right;
      display: inline-block;
    }
  }
  
.box-list-container > ul > li h5 span.box_alert {
    
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    font-size: 10px;
    color: #fff;
    font-style: normal;
    padding: 3px 12px 2px 12px;
    margin-left: 8px;
    position: relative;
    line-height: 1;
}
.box_alert_inactive {
  background-color: #dc3545;
  animation: swipe 0.3s ease-in-out infinite;
}
.box_alert_active {
  background-color: #589442;
  ;
}
ul.box_buttons {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  @media (max-width: 1081px) {
    ul.box_buttons {
      /* For less than 767px */
      position: static;
    }
  }
  ul.box_buttons li {
    display: inline-block;
    margin-right: 3px;
  }
  
  ul.box_list {
    list-style: none;
    margin: 15px 0;
    padding: 0;
  }
  ul.box_list li {
    margin-bottom: 5px;
  }
  .box_button {
    background: #e9ecef;
    color: #868e96;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    display: inline-block;
    outline: none;
    font-size: 10px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
    line-height: 1;
    font-weight: 500;
}
.box_button_edit:hover {
    background:#589442;
    color: #fefefe;
}
.box_button_delete:hover {
    background:#fc3545;
    color: #fefefe;
}


@keyframes colorAnimation {
  50% {
   color: inherit;
  }
  100% {
    color: #fefefe;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
      opacity: 1;
      transform: translateX(0);
    }
  100% {
      opacity: 0;
      transform: translateX(100%);
    }
}

@keyframes swipe {
  0% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(0);
  }
  80% {
      transform: translateX(-5px);
    }
  100% {
      transform: translateX(0);
    }
}

.box-switch {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 18px;
  cursor: pointer;
  
}

.box-switch svg {
  width: 100%;
  height: 100%;
}

.box-switch path {
  fill: #198754;
}

.box-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
}

.box-overlay h2 {
  color: #fefefe;
  font-weight: bold;
  font-size: 48px;
}
.back {
    background-image: url('../images/background1.jpg');
    height: 758px;
    border: 1px solid black;
}

#new-edit-user{
    width: 40%;
    margin: 0 auto;
    margin-top: 10px;
    height: 455px;
    padding: 0 !important;
}

#new8{
    border: 1px solid black ;
    width: 750px;
    margin: 0 auto;
    margin-top: 0px;
    height: 700px;
    transition: fadein 2s fadein;
    display: block;
    z-index: 10002;
    padding: 0 !important;  
}

#newreo{
    padding: 0 !important;
    width: 35%;
    margin: 0 auto;
    margin-top: 10%;
    height: 250px;   
    padding: 0 !important; 
}

  
#new-disable-user{
    width: 45%;
    margin: 0 auto;
    margin-top: 10px;
    height: 180px;
    padding: 0 !important;
}

#comment_payout{
    width: 20%;
    margin: 0 auto;
    margin-top: 10px;
    height: 180px;
    padding: 0 !important;
}


#new-base{
    width: 72%;
    margin: 0 auto;
    margin-top: 4%;
    height: 65vh;
    font-size: 15px !important;
    padding: 0 !important;
}

#assign-base{
    width: 35%;
    margin: 0 auto;
    margin-top: 50px;
    height: 600px;
    font-size: 16px !important;
    padding: 0 !important;
}

#code{
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    height: 80vh;
    font-size: 16px !important;
    padding: 0 !important;
}

.ant-table-thead .ant-table-cell {
    background-color: #4db6ac !important;
    text-align: center;
  }

  /* .ant-table-tbody .ant-table-cell {
    background-color: #4db6ac !important;
  }  */